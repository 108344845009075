(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.matches.controller:MatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.invoiceslist')
    .controller('invoiceslist', invoiceslist);

  function invoiceslist(AclService,Invoices,townHalls, $mdToast, $filter, $scope, $state,excel, $timeout, $mdDialog) {
    var vm = this;
    
      vm.townhalls = angular.copy(townHalls);
    vm.isAdm=AclService.isAdmin();
    vm.applyFilters = applyFilters;
    vm.llistatpersones=[];
      vm.matches = [];
    vm.selected = [];
    vm.select = undefined;
      vm.buttons = {
      dial: true,
      icon: 'more_vert',
      tooltip: 'ACTIONS',
      actions: [{
        icon: 'euro_symbol',
        tooltip: 'Facturar',
        execute: facturar
      }]
    };
     if (vm.townhalls.length === 1) {
       vm.selectedTownHall = vm.townhalls[0];
       vm.isCorn=(vm.selectedTownHall.id===16) || (vm.selectedTownHall.id===20);
    } 
    
   
     function facturar(){
       var idfact="";
       var diferents= vm.llistatpersones[0].idreceptor;
       var diforigin=true;
       var i=0;
         angular.forEach(vm.llistatpersones, function (persona) {
            
          if (persona.selected) {
              if(i===0){
                  diferents=persona.idreceptor;
              }
              i=i+1;
              idfact=idfact+persona.idinvoice+",";
               if(persona.idreceptor!==diferents){
                 diforigin=false;
             }
          }
         
      });
      idfact=idfact.substring(0,idfact.length-1);
  
         if(idfact.length===0){
             alert("Si us plau, has de seleccionar al menys una linea");
         }else{
             if (diforigin){
    // Appending dialog to document.body to cover sidenav in docs app
    var confirm = $mdDialog.confirm()
          .title('Estas segur/a de voler emetre les factures dels Tutors?')
          .textContent('Es generaran les factures dels partits pendents de facturar.')
          .ariaLabel('NEO')
          
          .ok('Si')
          .cancel('No');

    $mdDialog.show(confirm).then(function() {
        $state.go('home.facturar', {fechaini: moment(vm.iniDate).format('YYYY-MM-DD'),fechafin:moment(vm.endDate).format('YYYY-MM-DD'), idAjuntament:vm.selectedTownHall.id,ids:idfact});
    }, function() {
    
    });
         }else{
             alert("No es pot facturar en una mateixa factura 2 receptors diferents.");
         }
         }
  };
     
     
     
    function applyFilters() {
      vm.isFiltered = true;
      query( vm.iniDate, vm.endDate);
    }
    
     function query( iniDate, endDate) {
          var parameters = {
                initDate: moment(iniDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                isAdmin:AclService.isAdmin(),
                idAjuntament:vm.selectedTownHall.id
      };
      var an1=moment(iniDate).format('YYYY');
      var an2=moment(endDate).format('YYYY');
      if (an1===an2){
     vm.llistatpersones=Invoices.query(parameters);}
     else{
         alert("No es poden consultar dades de diferents anys.")
     }
     
     }
     
      $scope.exportToExcel= ((function() {
          var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
            , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
            , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
          return function(table, name) {
            table = document.getElementById(table);
            var ctx = {worksheet: name || 'Report', table: table.innerHTML};
            window.location.href = uri + base64(format(template, ctx));
          }
        })())
        
        
 

  

 

  }
}());


